window.jQuery = window.$ = require('jquery');
let processInclude = require('./util');

$(document).ready(function () {
  processInclude(require('./components/preventImageDownload'));
  processInclude(require('./dataLayer'));
  // processInclude(require('./components/menu'));
  // processInclude(require('./components/cookie'));
  // processInclude(require('./components/consentTracking'));
  // processInclude(require('./components/footer'));
  // processInclude(require('./components/miniCart'));
  // processInclude(require('./components/collapsibleItem'));
  processInclude(require('./components/search'));
  processInclude(require('./components/dataLayerEvent'));
  // processInclude(require('./components/clientSideValidation'));
  // processInclude(require('./components/countrySelector'));
  // processInclude(require('./components/toolTip'));
  processInclude(require('./components/bannerOverlay'));
  processInclude(require('./components/countrySelector'));
});

//require('./thirdParty/bootstrap');
require('bootstrap/js/src/carousel.js');
require('./components/spinner');
