'use strict';
import { createCookie, eraseCookie } from './cookie';

//Banner Close
$('.button-close').on('click', function () {
  $('.banner-overlay').slideUp('slow');
  createCookie('bannerOverlay-closed', true, 1);
});

// Redirect to advisable Site and Country Selector
$('.banner-overlay-cta a').on('click', function (e) {
  e.preventDefault();

  eraseCookie('splash-page', '', -1);
  const url = e.currentTarget.href;
  window.location.href = url;
});
