function getCurrencyCode() {
  return $('body').data('currencyCode');
}

function updateImpressionList(impressionID, list) {
  if (!impressionID || !list || !__dl.cardImpressions[impressionID]) return false;
  __dl.cardImpressions[impressionID].list = list;
  return true;
}

function dtlCardData(cardData, currentList, position) {
  if (!cardData || !cardData.cardtype) return null;
  let dtl = cardData.productcard || {};
  dtl.position = position || 1;
  //dtl.list = currentList || '';
  if (cardData.cardtype === 'product_std') {
    if (!dtl.id) return null;
  } else {
    dtl.name = dtl.name;
    dtl.id = dtl.id;
    dtl.category = cardData.category;
  }
  return dtl;
}

function pushImpression(cards, rifinements) {
  rifinements = rifinements || {};
  dataLayer.push({
    event: rifinements.event || 'productImpressions',
    ecommerce: {
      currencyCode: rifinements.currencyCode || getCurrencyCode() || '',
      impressions: cards,
    },
  });
}

function processImpressions(impressionLists, rifinements) {
  if (!impressionLists || !impressionLists.length) return false;
  impressionLists.forEach(function (list) {
    if (list.length < 13) {
      pushImpression(list, rifinements);
    } else {
      let i,
        j = list.length,
        chunk = 12;
      for (i = 0; i < j; i += chunk) {
        pushImpression(list.slice(i, i + chunk), rifinements);
      }
    }
  });
}

function trackClick(impressionID, _url) {
  if (!impressionID) return false;
  let _impr = __dl.cardImpressions && __dl.cardImpressions[impressionID];
  if (!_impr) return false;
  let dtl = Object.assign({}, _impr);
  let list = dtl.list;
  delete dtl.list;
  console.log(impressionID, 'click:', dtl);
  dataLayer.push({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: {
          list: list,
        },
        products: [dtl],
      },
    },
    eventCallback: function () {
      document.location = _url;
    },
  });
}

function checkForNewImpressions($region, force, dlRifinements, elementPosition) {
  let impressionLists = [];
  let cardList;
  let $elements = $region ? ($region instanceof jQuery ? $region : $($region)) : undefined;
  let position = elementPosition || 0
  let rifinements = dlRifinements || {};
  let forceFlag = force || false;
  let $selector;
  let _list;
  //verifico se esiste una regione e se esiste verifico se è un parent o se è l'elemento da gestire
  ($selector = $elements ? ($elements.filter('[data-impressionid]').length && $elements) || $elements.find('[data-impressionid]') : $('[data-impressionid]')), (_list = []);

  $selector.each(function (i, el) {
    let $el = $(el);
    let cardData = $el.data();
    if (!cardData.cardtype || !cardData.impressionid) return true;

    //verifico di non avere una card già in "pancia" - ATTENZIONE impressionid duplicati
    let impr = __dl.cardImpressions[cardData.impressionid];

    let $parent = $el.parents('[data-listcontext]'),
      //cerco di recuperare l'utlima positione un cache per questo elemento
      cachePosition = forceFlag ? position : $parent.data('listposition') || position,
      currentList = (!impr || forceFlag ? $parent.data('listcontext') : impr.list) || '';

    //verifco se processare o solo pre-processare l'impression
    let processing = forceFlag || (!cardData.delayimpression && !impr);
    let preprocessing = processing || !impr;

    if (preprocessing) {
      if (currentList !== cardList) {
        // processo solo se richiesto
        _list = [];
        if (processing) impressionLists.push(_list);
        position = cachePosition + 1;
      } else {
        position++;
      }

      cardList = currentList;
      $parent.data('listposition', position);

      let dtl = dtlCardData(cardData, currentList, position);
      if (!dtl) return true;
      __dl.cardImpressions[cardData.impressionid] = dtl;

      // processo solo se richiesto
      if (processing) _list.push(dtl);
    }
  });
  if (impressionLists.length) processImpressions(impressionLists, rifinements.impression);
}

module.exports = {
  // clic: () => {
  //   $('[data-gtm-click]').on('click', () => {
  //     let base = {
  //       event: 'interaction',
  //       eventCategory: 'clic'
  //     };
  //     let dataGtm = JSON.parse($(this).data('gtm-click'));
  //     dataLayer.push({
  //       ...base,
  //       ...dataGtm
  //     });
  //   });
  // },

  pushEventsOnLoad: function () {
    window.dataLayer = window.dataLayer || [];
    if ('__dl' in window && window.__dl && window.__dl.pageload) {
      [].concat(window.__dl.pageload).forEach(function (event) {
        if (event && Object.keys(event).length > 0) {
          const lastOrderId = window.localStorage.getItem('lastOrderId');
          const currentOrder = event.ecommerce
            && event.ecommerce.purchase
            && event.ecommerce.purchase.actionField
            && event.ecommerce.purchase.actionField.id || '';

          if(event.event !== 'purchase'){
            dataLayer.push(event);
          } else if(currentOrder !== lastOrderId){
            dataLayer.push(event);
            window.localStorage.setItem('lastOrderId', currentOrder);
          }
        }
      });
    }
    window.__dl = window.__dl || {};
    window.__dl.cardImpressions = {};
    window.__dl.pushImpression = pushImpression;
    window.checkForNewImpressions = checkForNewImpressions;
  },

  handleAjaxDataLayer: function () {
    $.ajaxPrefilter(function (options, originalOptions, jqXhr) {
      let originalSuccess = options.success;
      options.success = function (data) {
        if (data && data.dataLayer) {
          dataLayer.push(data.dataLayer);
        }
        originalSuccess && originalSuccess.apply(jqXhr, arguments);
      };
    });
  },

  header: function () {
    [
      {
        selector: '.logo-home',
        on: 'click',
        eventLabel: 'logo',
        eventLabel2: 'logo',
      },
      {
        selector: '.icon-search',
        on: 'click',
        eventLabel: 'navigation states',
        eventLabel2: 'search',
      },
      {
        selector: '.nav-item--wishlist a',
        on: 'click',
        eventLabel: 'navigation states',
        eventLabel2: 'wishlist',
      },
      {
        selector: '.nav-item--login a',
        on: 'click',
        eventLabel: 'navigation states',
        eventLabel2: 'account',
      },
      {
        selector: '.nav-item--minicart',
        on: 'click',
        eventLabel: 'navigation states',
        eventLabel2: 'minicart',
      },
    ].forEach(function (e) {
      $(e.selector).on(e.on, function () {
        dataLayer.push({
          event: 'interaction',
          eventCategory: 'clic',
          eventAction: 'header',
          eventLabel: e.eventLabel,
          eventLabel2: e.eventLabel2,
        });
      });
    });

    // TODO voice and image search
  },

  menu: function () {
    $('#menu a.menu-item[href]').on('click', function () {
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'menu',
        eventLabel: $(this).hasClass('menu-item-1-liv') ? 'firstLevel' : 'secondLevel',
        eventLabel2: $(this).data('gtm-label2') || $(this).text(),
      });
    });
  }.bind(this),

  account: function () {
    $('form.login').one('submit', function () {
      let loginUrl = $(this).attr('action');
      $.ajaxPrefilter(function (options) {
        let originalSuccess = options.success;
        if (options.url === loginUrl) {
          options.success = function (data) {
            if (data.success) {
              dataLayer.push({
                event: 'interaction',
                eventCategory: 'click',
                eventAction: 'login & registration',
                eventLabel: 'login',
                eventLabel2: 'confirmed',
              });
            }
            originalSuccess && originalSuccess.apply(this, arguments);
          };
        }
      });
    });

    $('.btn-link[href$=register]').one('click', function () {
      return dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'login & registration',
        eventLabel: 'sign up',
        eventLabel2: 'step 1',
      });
    });

    $('form.registrationStep0').one('submit', function () {
      let registration0Url = $(this).attr('action');

      $.ajaxPrefilter(function (options0) {
        let originalSuccess0 = options0.success;

        if (options0.url === registration0Url) {
          options0.success = function (data) {
            originalSuccess0 && originalSuccess0.apply(this, [].slice.call(arguments));

            if (data && data.success) {
              let $formRegistrationStep1 = $('form.registrationStep1');
              if ($formRegistrationStep1.length > 0) {
                dataLayer.push({
                  event: 'interaction',
                  eventCategory: 'clic',
                  eventAction: 'login & registration',
                  eventLabel: 'sign up',
                  eventLabel2: 'step 2',
                });
              }

              $formRegistrationStep1.one('submit', function () {
                let registration1Url = $(this).attr('action');

                $.ajaxPrefilter(function (options1) {
                  let originalSuccess1 = options1.success;

                  if (options1.url === registration1Url) {
                    options1.success = function (data1) {
                      if (data1 && data1.success) {
                        dataLayer.push({
                          event: 'interaction',
                          eventCategory: 'clic',
                          eventAction: 'login & registration',
                          eventLabel: 'sign up',
                          eventLabel2: data.redirectUrl && data.redirectUrl.indexOf('registrationResult') >= 0 ? 'sent' : 'confirmed',
                        });
                      }
                      originalSuccess1 && originalSuccess1.apply(this, [].slice.call(arguments));
                    };
                  }
                });
              });
            }
          };
        }
      });
    });

    if (location.search && location.search.indexOf('registrationResult=REGISTERED') > 0) {
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'login & registration',
        eventLabel: 'sign up',
        eventLabel2: 'confirmed',
      });
    }
  }.bind(this),

  footer: function () {
    let pushDaralayer = function (data) {
      dataLayer.push(
        Object.assign(
          {
            event: 'interaction',
            eventCategory: 'clic',
            eventAction: 'footer',
            eventLabel: '',
            eventLabel2: '',
          },
          data
        )
      );
    };
    $('footer').on('click', 'a.footer-link', function (e) {
      let link = $(this);
      let label1 = link.parents('[data-gtm-label1]').data('gtm-label1') || '';
      pushDaralayer({
        eventLabel: label1,
        eventLabel2: link.data('gtm-label2') || link.text(),
      });
    });

    $('.country-selector-trigger button').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'country & language',
        eventLabel2: $(this).text(),
      });
    });

    $('.footer-social').on('click', 'a', function (e) {
      pushDaralayer({
        eventLabel: 'social',
        eventLabel2: $(this).text().toLowerCase(),
      });
    });

    $('.nl-subscription-form').on('nl-submitted', function (e) {
      pushDaralayer({
        eventLabel: 'newsletter',
        eventLabel2: 'sent',
      });
    });
  }.bind(this),

  popup: function () {
    $(document).on('shown.bs.modal', '.modal', function () {
      let modal = $(this);
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'impression',
        eventAction: 'popup',
        eventLabel: modal.attr('id'),
      });

      switch (modal.attr('id')) {
        case 'countryLanguageModal':
          $('.country-selector-lang').on('click', 'a', function () {
            let link = $(this);
            dataLayer.push({
              event: 'interaction',
              eventCategory: 'clic',
              eventAction: 'popup',
              eventLabel: 'countryLanguageModal',
              eventLabel2: link.data('locale'),
            });
          });
          break;
        case 'countrySelectorModal':
          $('.country-selector-list').on('click', 'a', function () {
            let link = $(this);
            dataLayer.push({
              event: 'interaction',
              eventCategory: 'clic',
              eventAction: 'popup',
              eventLabel: 'countrySelectorModal',
              eventLabel2: link.data('locale'),
            });
          });
          break;
      }
    });
  }.bind(this),

  filters: function () {
    $(document).on('search:layout', function (e, data) {
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'toggle layout',
        eventLabel: 'toggle layout',
        eventLabel2: data,
      });
    });

    let pushFilter = function () {
      let refinements = $('.refinement-selected')
        .toArray()
        .map(function (el) {
          return {
            filter: $(el).data('refinement-id'),
            value: $(el).data('refinement-value'),
          };
        })
        .reduce(function (acc, ref) {
          if (!(ref.filter in acc)) {
            acc[ref.filter] = [ref.value];
          } else {
            acc[ref.filter].push(ref.value);
          }
          return acc;
        }, {});

      dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'filter',
        eventLabel: Object.keys(refinements)
          .map(function (key) {
            return `${key}:${refinements[key].join(',')}`;
          })
          .join('_'),
        eventLabel2: 'sort:' + ($('.refinement-sort-by input:checked').attr('id') || 'none'),
      });
    };

    $(document).on('search:sort', pushFilter);
    $(document).on('search:filterSuccess', pushFilter);
  },

  pdp: function () {
    [
      {
        selector: '#productLocatorPopupTrigger',
        on: 'click',
        eventLabel: 'omnichannel',
        eventLabel2: 'reserve in store',
      },
      {
        selector: '#descriptionPopupTrigger',
        on: 'click',
        eventLabel: 'tab product presentation',
        eventLabel2: 'description',
      },
      {
        selector: '#deliveryPopupTrigger',
        on: 'click',
        eventLabel: 'tab product presentation',
        eventLabel2: 'delivery and returns',
      },
      {
        selector: '.customer-service button',
        on: 'click',
        eventLabel: 'tab product presentation',
        eventLabel2: 'customer service',
      },
      // inserire elementi legati alla voci interne della tab customer service avranno 'eventLabel': 'tab product presentation – customer
      // services'.
      {
        selector: '.product-gallery .carousel-item a',
        on: 'click',
        eventLabel: 'top product carousel',
        eventLabel2: 'image-zoom',
      },
    ].forEach(function (e) {
      $(e.selector).on(e.on, function () {
        dataLayer.push({
          event: 'interaction',
          eventCategory: 'eCommerce',
          eventAction: 'product exploration',
          eventLabel: e.eventLabel,
          eventLabel2: e.eventLabel2,
        });
      });
    });
  },

  plp: function () {
    [
      {
        selector: '.product-card-swatch',
        on: 'click',
        eventAction: 'card',
        eventLabel: 'colour',
        eventLabel2: 'choose',
      },
      {
        selector: '.product-card .js-size-selector',
        on: 'click',
        eventAction: 'card',
        eventLabel: 'size',
        eventLabel2: 'choose',
      },
    ].forEach(function (e) {
      $(e.selector).on(e.on, function () {
        dataLayer.push({
          event: 'interaction',
          eventCategory: 'eCommerce',
          eventAction: e.eventAction,
          eventLabel: e.eventLabel,
          eventLabel2: e.eventLabel2,
        });
      });
    });
  },

  checkout: function () {
    $(document).on('checkout:step', function (e, step) {
      if (dataLayer && step) {
        let eventCheckout = dataLayer.filter(function (e) {
          return e.event === 'checkout' && e.ecommerce && e.ecommerce.checkout && e.ecommerce.checkout.actionField;
        });
        if (eventCheckout.length > 0) {
          let newEvent = {
            ...eventCheckout[0],
          };
          newEvent.ecommerce.checkout.actionField.step = step;
          dataLayer.push(newEvent);
        }
      }
    });
  },

  pdStaticContent: function () {
    let baseConf = {
      on: 'click',
      dtl: {
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'carousel',
      },
    };
    [
      {
        //BLAND DIFFERENT
        selector: '.blend-different-slider_wrapper .slick-arrow',
        dtl: {
          eventLabel: 'blend different',
          eventLabel2: 'arrow',
        },
      },
      {
        //SHOP BY COLOR
        selector: '.shop-by-colour .slick-arrow',
        dtl: {
          eventLabel: 'shop by colour',
          eventLabel2: 'arrow',
        },
      },
      {
        on: 'start',
        selector: '.shop-by-colour',
        dtl: {
          eventLabel: 'shop by colour',
          eventLabel2: 'start from here',
        },
      },
      {
        on: 'wheelslice',
        selector: '.shop-by-colour',
        dtl: {
          eventLabel: 'shop by colour',
          eventLabel2: 'color',
        },
      },
      {
        on: 'filters',
        selector: '.shop-by-colour',
        dtl: function (e, event, _this) {
          return {
            eventLabel: 'shop by colour',
            eventLabel2: ($(_this).data('cid') || 'filter').toLowerCase(),
          };
        },
      },
      {
        //MIX AND MATCH
        selector: '.content-mix-and-match .filter',
        dtl: {
          eventLabel: 'mix & match',
          eventLabel2: 'filter',
        },
      },
      {
        //MIX AND MATCH
        selector: '.content-mix-and-match .slick-arrow',
        dtl: {
          eventLabel: 'mix & match',
          eventLabel2: 'arrow',
        },
      },
      {
        //CARUSEL SLIDE
        selector: '.hero-carousel__slide a',
        dtl: {
          event: 'promoClick',
          eventCategory: 'clic',
          eventAction: 'banner',
          eventLabel: 'hero carousel',
          eventLabel2: '',
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  name: 'hero carousel',
                  creative: '',
                },
              ],
            },
          },
        },
      },
    ].forEach(function (e) {
      $('body').on(e.on || baseConf.on, e.selector, function (event) {
        try {
          let dtl = typeof e.dtl === 'function' ? e.dtl(e, event, this) : e.dtl;
          dtl = Object.assign({}, baseConf.dtl, e.dtl);
          dataLayer.push(dtl);
        } catch (e) {
          console.log('datalayer: error on event processing');
        }
      });
    });
  }.bind(this),

  cards: function () {
    $('body').on('click', 'product-card-swatch', function (e) {
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'eCommerce',
        eventAction: 'card',
        eventLabel: 'colour',
        eventLabel2: 'choose',
      });
    });

    if (!$('body').prop('id') == 'search-show') {
      $('body').on('click', '[data-cardtype]:not(.o-card-article) a', function (e) {
        let impressionID = $(this).parents('[data-impressionid]').data('impressionid');
        trackClick(impressionID, $(this).attr('href'));
      });
    }

    $('body').on('datalinkclick', '.o-card-article', function (e) {
      let data = $(this).data();
      trackClick(data.impressionid, data.link);
    });

    $('.blend-different-slider_wrapper').on('datalinkclick', '.single-slide', function (e) {
      let data = $(this).data();
      trackClick(data.impressionid, data.link);
    });

    checkForNewImpressions();
  }.bind(this),

  customerservice: function () {
    let pushDaralayer = function (data) {
      dataLayer.push(
        Object.assign(
          {
            event: 'interaction',
            eventCategory: 'clic',
            eventAction: 'customer services',
            eventLabel: '',
            eventLabel2: '',
          },
          data
        )
      );
    };
    $('.customerservice-head .nav-link').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'tab',
        eventLabel2: $(this).attr('id').toLowerCase(),
      });
    });

    $('#tabs-Faq .nav-link').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'faqs',
        eventLabel2: $(this).attr('href').replace('#', '').toLowerCase(),
      });
    });

    $('#pills-Faq .collapse-wrapper .title').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'faqs',
        eventLabel2: $(this).text().toLowerCase(),
      });
    });

    $('#tabs-sizeguide .nav-link').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'size guide',
        eventLabel2: $(this).attr('href').replace('#', '').toLowerCase(),
      });
    });

    $('.trackorder').on('submit', function (e) {
      pushDaralayer(
        $('[page-id=return]').length > 0
          ? {
              eventLabel: 'returns',
            eventLabel2: 'step1',
          }
          : {
            eventLabel: 'order status',
              eventLabel2: 'view order',
          }
      );
    });

    $('[data-pageid=return]').on('click', '.slick-arrow', function (e) {
      pushDaralayer({
        eventLabel: 'returns',
        eventLabel2: 'explanation arrow',
      });
    });

    $('.contact-us').on('submitted', function (e) {
      pushDaralayer({
        eventLabel: 'contact us',
        eventLabel2: 'sent',
      });
    });

    $('body').on('datalinkclick', '.widget-customerservice-list-item', function (e) {
      let elementName = $(this).data('link').indexOf('tel') == 0 ? 'tel' : 'email';
      pushDaralayer({
        eventLabel: 'contact us',
        eventLabel2: elementName,
      });
    });
  }.bind(this),

  delayedImpressions: function () {
    let $blendSlider = $('.blend-different-slider_wrapper .carousel');
    $blendSlider.each(function (i, el) {
      let $el = $(el);
      let listname = 'blend different top';
      if (i > 0) {
        listname = 'blend different bottom';
      }
      $el.parents("[data-listcontext='blend different']").data('listcontext', listname);

      $el.find('[data-impression]').each(function (_x, impEl) {
        let imprID = $(impEl).data('[data-impressionid]');
        updateImpressionList(imprID, listname);
      });

      $el.on('init', function (e, slick) {
        let $activeSlide = $(slick.$slides[0]);
        checkForNewImpressions($activeSlide, true);
      });
    });

    $('.recommendations').on('load', function () {
      setTimeout(function () {
        const $products = $('.recommendations').find('.product');
        checkForNewImpressions($products, true);
      }, 500);
    });

    $('.shop-by-colour').on('start', function () {
      setTimeout(
        function () {
          let $activeSlide = $(this).find('.layer--visible .slick-active');
          checkForNewImpressions($activeSlide, true);
        }.bind(this),
        1000
      );
    });

    $('.shop-by-colour').on('afterChange', function () {
      let $activeSlide = $(this).find('.layer--visible .slick-active');
      checkForNewImpressions($activeSlide, true);
    });

    //need ispiration CARUSEL BUILD
    $('body').on('carousels:build', function (e) {
      let $ni = $("[data-listcontext='NeedInspiration]");
      if ($ni.length) checkForNewImpressions($ni, true);
    });

    //category selector
    $('.subcategories_withicons_wrapper .carousel').on('init', function (event, slick, currentSlide, nextSlide) {
      const $slides = $(this).find('.slick-slide:not(.slick-cloned)');
      checkForNewImpressions($slides, true);
      //track click on elements with data-link
      $slides.find('[data-link]').on('click', function () {
        let impression = $(this).closest('[data-impressionid]');
        let $link = $(this).data('link');
        if (impression.length) trackClick(impression.data('impressionid'), $link);
      });
    });

    $(document).ready(function () {
      let promotions = [];
      $('.experience-component').each(function (i, promo) {
        if (promo.querySelector('a')) {
          let promotionId = promo.classList[1].split('-')[1];
          let promotionName = promo.querySelector('h2') ? promo.querySelector('h2').innerText : '';

          promotions.push({
            id: promotionId,
            name: promotionName,
            creative: promotionName,
            position: 'default',
          });
        }
      });
      // if (promotions.length > 0) {
      //   dataLayer.push({
      //     event: 'promoView',
      //     ecommerce: {
      //       promoView: {
      //         promotions: promotions,
      //       },
      //     },
      //   });
      // }

      $('.wishlist-product').on('click', function (e) {
        let card = $(e.currentTarget).parent().data('impressionid');
        let urlLink = $(e.currentTarget).children().prop('href');

        if (e.target.localName === 'button') {
          return;
        }

        trackClick(card, urlLink);
      });

      $('body').on('click', '.product', function (e) {
        const productCard = $(e.currentTarget).data('productcard');
        const urlLink = $(e.currentTarget).find('a').prop('href');
        let currentPosition = Array.from($('.product')).findIndex(node => node.isEqualNode(e.currentTarget));
        const list = productCard.list;
        delete productCard.list;

        dataLayer.push({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: {
                list: list,
              },
              products: [{
                ...productCard,
                position: ++currentPosition
              }],
            },
          },
          eventCallback: function () {
            document.location = urlLink;
          },
        });
      });

      if ($('body').prop('id') === 'page-homepage') {
        $('.experience-component.experience-product_carousel_grid').on('load', function () {
          setTimeout(function () {
            const $products = $(this).find('.product-tile a');
            checkForNewImpressions($products, true);
          }, 1000);
        });

        $('.product-tile').on('click', function (e) {
          let card = $(e.currentTarget).children().data('impressionid');
          let urlLink = $(e.currentTarget).children().prop('href');

          trackClick(card, urlLink);
        });
      } else {
        $('.experience-component').on('click', function (e) {
          if (e.currentTarget.querySelector('a')) {
            let promotionId = e.currentTarget.classList[1].split('-')[1];
            let data = e.currentTarget.querySelector('h2') ? e.currentTarget.querySelector('h2').innerText : ''
            let promotionData = data.toLocaleLowerCase().replace(/-| /g, '_');
            dataLayer.push({
              event: 'promoClick',
              ecommerce: {
                promoClick: {
                  promotions: {
                    id: promotionId,
                    name: promotionData,
                    creative: promotionData,
                    position: 'default',
                  },
                },
              },
            });
          }
        });
      }
    });
  }.bind(this),
  GAevent: function () {
    let pushDaralayer = function (data) {
      dataLayer.push(
        Object.assign(
          {
            event: 'GAevent',
            eventCategory: '',
            eventAction: 'click',
            eventLabel: '',
          },
          data
        )
      );
    };
    $('.primary-link, .sub-menu-item').on('click', function (e) {
      pushDaralayer({
        eventID: '01',
        eventCategory: 'menu',
        eventAction: $(this).hasClass('primary-link') ? 'first_position' : 'second_position',
        eventLabel: e.currentTarget.innerText.toLowerCase(),
      });
    });

    $('.right-actions a').one('click', function (e) {
      let target = e.currentTarget;
      let label = target.getAttribute('data-label');
      pushDaralayer({
        eventID: '02',
        eventCategory: 'header',
        eventLabel: label,
      });
    });

    $(document).on('click', 'form.search-form button[type="submit"]', function (e) {
      let target = $('form.search-form').find('input.search-field').val() || $('form.search-form').find('input.input-mobile').val();
      pushDaralayer({
        eventID: '03',
        eventCategory: 'search',
        eventAction: 'free_search',
        eventLabel: target,
      });
    });

    $('.js-suggestions-wrapper').on('click', function (e) {
      let target = $('form.search-form').find('input.search-field').val() || $('form.search-form').find('input.input-mobile').val();
      pushDaralayer({
        eventID: '03',
        eventCategory: 'search',
        eventAction: 'suggested_search',
        eventLabel: target,
      });
    });

    $('.country-selector-modal').on('click', 'a', function (e) {
      let target = e.currentTarget;
      let selection = target.innerText;
      let parent = $(target).parent('dt.country');
      if ($(parent).hasClass('country')) {
        let language = $(target).parent().siblings().children()[0].innerText;
        selection = selection + '_' + language;
      } else {
        let country = $(target).parent().siblings()[0].innerText;
        selection = country + '_' + selection;
      }

      pushDaralayer({
        eventID: '04',
        eventCategory: 'language_country',
        eventLabel: selection.toLocaleLowerCase(),
      });
    });

    $('.footer-list-item a').on('click', function (e) {
      let target = e.currentTarget;
      let label = target.getAttribute('data-label');
      pushDaralayer({
        eventID: '05',
        eventCategory: 'footer',
        eventLabel: label,
      });
    });

    $('.footer-socials a').on('click', function (e) {
      let label = e.currentTarget.ariaLabel;
      pushDaralayer({
        eventID: '06',
        eventCategory: 'social',
        eventLabel: label,
      });
    });

    //TODO
    // $(document).on('submit', 'form.newsletter-form',function (e) {
    //   pushDaralayer({
    //     eventID:'07',
    //     eventCategory: 'newsletter',
    //     eventAction: '$$$action_type$$$',
    //     eventLabel: '$$$subscription$$$'
    //   });
    // });

    $('.new-account a').on('click', function (e) {
      pushDaralayer({
        eventID: '08',
        eventCategory: 'registration',
        eventAction: 'step1',
        eventLabel: 'step1_ok',
      });
    });

    $('.my-account_sidebar--menu li, .btn-logout').on('click', function (e) {
      let target = e.currentTarget;
      let label = target.getAttribute('data-label').toLowerCase();
      pushDaralayer({
        eventID: '12',
        eventCategory: 'my_account',
        eventAction: 'my_account_menu',
        eventLabel: label,
      });
    });

    //TODO da controllare
    // $('.grid-header').on('click', '.refinements li button', function (e) {
    //   pushDaralayer({
    //     eventID: '13',
    //     eventCategory: 'filter_on_listing',
    //     eventAction: '$$$filter_criteria$$$',
    //     eventLabel: '$$$filter_attribute$$$',
    //   });
    // });

    // FILTERS
    $('.grid-header').on('click', '.js-filters-panel', function (e) {
      const category = $(e.target).closest('.js-dropdown-content').prop('id');
      const filter = $(e.target).find('.js-refinements').data('refinement-value');

      pushDaralayer({
        eventID: '13',
        eventCategory: 'filter_on_listing',
        eventAction: category.split('-')[1],
        eventLabel: filter.toString().replaceAll('-', '_').toLocaleLowerCase(),
      });
    });

    // FILTER SORT BY
    $('.grid-header').on('change', '.js-sort-select', function (e) {
      const sortBy = $('.js-sort-select option:selected').attr('data-id');

      dataLayer.push({
        event: 'GAevent',
        eventID: '13',
        eventCategory: 'filter_on_listing',
        eventAction: 'sort_product',
        eventLabel: sortBy.replaceAll('-', '_'),
      });
    });

    // REMOVE FILTERS
    $('.grid-header').on('click', '.filter-value button', function (e) {
      let filter = e.currentTarget.dataset.filter;
      pushDaralayer({
        eventID: '13',
        eventCategory: 'filter_on_listing',
        eventAction: 'remove_filter',
        eventLabel: filter.toLowerCase().replace(' ', '_'),
      });
    });

    //TODO con il catalogo saranno da mettere i colori in inglese
    $('.color-attribute ').on('click', function (e) {
      let pid = $('.product-wrapper').data('pid');
      let target = e.currentTarget;
      let label = target.getAttribute('data-label').toLowerCase();
      pushDaralayer({
        eventID: '14',
        eventCategory: 'product',
        eventAction: 'color',
        eventLabel: label,
        sku: pid,
      });
    });

    //Missing social share
    $('.btn-share-container').on('click', function (e) {
      let pid = $('.product-wrapper').data('pid');
      let social = $(this).find('button')[0].dataset.service;
      pushDaralayer({
        eventID: '15',
        eventCategory: 'product',
        eventAction: 'share_product',
        eventLabel: social,
        sku: pid,
      });
    });

    $('.find-your-size a, .breadcrumb, .link-boutique a').on('click', function (e) {
      let pid = $('.product-wrapper').data('pid');
      let target = e.currentTarget;
      let label = target.getAttribute('data-label') || '';
      pushDaralayer({
        eventID: '16',
        eventCategory: 'product',
        eventLabel: 'click',
        eventAction: label.toLowerCase(),
        sku: pid,
      });
    });

    $('.js-add-to-wishlist').on('click', function (e) {
      let wasActive = $(e.currentTarget).hasClass('in-wishlist');
      let pid = $('.product-wrapper').data('pid');
      let cartPid = $('.cart-product-wrapper').data('pid');
      let pageId = document.body.id;
      let event = pageId.split('-')[0];
      let userLogged = document.querySelector('.userAuth').innerHTML;
      if (event === 'wishlist' && userLogged === 'true') {
        event = 'account';
      }
      pushDaralayer({
        eventID: '17',
        eventCategory: 'product',
        eventAction: wasActive ? 'remove_from_wishlist' : 'add_to_wishlist',
        eventLabel: event === 'product' ? 'product' : event,
        sku: pid ? pid : cartPid,
      });
    });

    //TODO
    $('.remove-from-wishlist').on('click', function (e) {
      let pid = e.currentTarget.dataset.pid;
      let userLogged = document.querySelector('.userAuth').innerHTML;
      pushDaralayer({
        eventID: '18',
        eventCategory: 'product',
        eventAction: 'remove_from_wishlist',
        eventLabel: userLogged === 'true' ? 'account' : 'wishlist',
        sku: pid,
      });
    });

    $(document).on('minicart:close', function () {
      pushDaralayer({
        eventID: '20',
        eventCategory: 'minicart',
        eventAction: 'click',
        eventLabel: 'minicart_close',
      });
    });

    $(document).on('minicart:shoppingBag', function () {
      pushDaralayer({
        eventID: '20',
        eventCategory: 'minicart',
        eventAction: 'click',
        eventLabel: 'view_shopping_bag',
      });
    });

    $(document).on('click', '.link-shopping-bag', function () {
      pushDaralayer({
        eventID: '20',
        eventCategory: 'minicart',
        eventAction: 'click',
        eventLabel: 'view_shopping_bag',
      });
    });

    //TODO non funziona la selezione dentro la modale
    $(document).on('click', '.checkout-btn', function (e) {
      let target = e.currentTarget;
      let dataTarget = target.getAttribute('data-target').toLowerCase();
      pushDaralayer({
        eventID: '21',
        eventCategory: 'checkout',
        eventAction: 'go_to_checkout',
        eventLabel: dataTarget,
      });
    });

    //ANDRA GESTITO DA COOKIEBOT SE SI RIESCE
    $(document).on('click', '.hcb__actions__confirm', function (e) {
      pushDaralayer({
        eventID: '22',
        eventCategory: 'banner_cookie',
        eventAction: 'reload_on_cookie_consent',
        eventLabel: 'cookie_all_accepted',
        marketingCookie: 1,
      });
    });

    $(document).on('click', '.hcb__close-icon', function (e) {
      pushDaralayer({
        eventID: '22',
        eventCategory: 'banner_cookie',
        eventAction: 'reload_on_cookie_consent',
        eventLabel: 'cookie_deny',
        marketingCookie: 0,
      });
    });

    setTimeout(function () {
      if ($('.hinto-cookie-banner').is(':visible')) {
        $('.hcb__actions__preferences').on('click', function () {
          setTimeout(function () {
            $('.hcm__actions button').on('click', function (e) {
              const elements = $('.hinto-cookie-modal').find('.hcm__section__switch.checked');

              if ($(elements).parent().text().includes('Marketing')) {
                pushDaralayer({
                  eventID: '22',
                  eventCategory: 'banner_cookie',
                  eventAction: 'reload_on_cookie_consent',
                  eventLabel: 'cookie_all_accepted',
                  marketingCookie: 1,
                });
              } else {
                pushDaralayer({
                  eventID: '22',
                  eventCategory: 'banner_cookie',
                  eventAction: 'reload_on_cookie_consent',
                  eventLabel: 'cookie_deny',
                  marketingCookie: 0,
                });
              }
            });
          }, 100);
        });
      }
    }, 1000);

    $(document).on('click', '.checkout-login a', function (e) {
      pushDaralayer({
        eventID: '23',
        eventCategory: 'checkout',
        eventAction: 'click',
        eventLabel: 'checkout_register',
      });
    });

    //MANCA CONTACT FORM
    // $('').on('click', function (e) {
    //   pushDaralayer({
    //     eventID:'24',
    //     eventCategory: 'customer_service',
    //     eventAction: 'contact_form',
    //     eventLabel: '$$$reason$$$'
    //   });
    // });

    $('.customer-service-accordion-content').on('click', function (e) {
      let section = $('.my-account_sidebar--menu').find('li.active').children().text();
      let selectedItems = $(e.currentTarget).find('h2').text();
      pushDaralayer({
        eventID: '25',
        eventCategory: 'customer_service',
        eventAction: section.toLowerCase(),
        eventLabel: selectedItems.replace(' ', '_').toLowerCase(),
      });
    });

    $('#storelocator').on('click', 'ul li', function (e) {
      let city = e.currentTarget.getAttribute('data-city').toLowerCase();
      let storeName = e.currentTarget.getAttribute('data-store').toLowerCase();
      pushDaralayer({
        eventID: '26',
        eventCategory: 'store_locator',
        eventAction: 'store_selection',
        eventLabel: city.replace(' ', '_'),
        moreInfo: storeName.replace(' ', '_'),
      });
    });

    $('#storelocator').on('click', 'ul li .phone, ul li .get-direction', function (e) {
      let target = e.currentTarget;
      let label = target.getAttribute('data-label').toLowerCase();
      let selectedStore = target.closest('li').getAttribute('data-store').toLowerCase();
      let storeCity = target.closest('li').getAttribute('data-city').toLowerCase();
      pushDaralayer({
        eventID: '27',
        eventCategory: 'store_locator',
        eventAction: label.replace(' ', '_'),
        eventLabel: storeCity.replace(' ', '_'),
        moreInfo: selectedStore.replace(' ', '_'),
      });
    });

    $(document).on('submit', '#storelocator form', function (e) {
      let target = e.currentTarget;
      let value = target[0].value;
      pushDaralayer({
        eventID: '28 ',
        eventCategory: 'store_locator',
        eventAction: 'search',
        eventLabel: value,
      });
    });

    $('.js-video').on('click', '.icon-stop, .icon-play', function (e) {
      let pageId = document.body.id;
      let action = e.currentTarget.dataset.target;
      pushDaralayer({
        eventID: '29',
        eventCategory: 'video',
        eventAction: pageId.split('-')[1],
        eventLabel: 'video',
        moreInfo: action,
      });
    });

    $('.editorial-link a').on('click', function (e) {
      let pageId = document.body.id;
      let articleTitle = $('.component-collection__title').text();
      let category = $('.editorial-link a').data('category');
      pushDaralayer({
        eventID: '30',
        eventCategory: 'articles',
        eventAction: pageId.split('-')[1],
        eventLabel: articleTitle,
        moreInfo: category,
      });
    });

    $('.js-articles-grid').on('click', 'li a', function (e) {
      let pageId = document.body.id;
      let articleTitle = this.querySelector('.content .title').innerHTML;
      let category = this.querySelector('.content .info .category').innerHTML;
      pushDaralayer({
        eventID: '30',
        eventCategory: 'articles',
        eventAction: pageId.split('-')[1],
        eventLabel: articleTitle.replaceAll(' ', '_').toLowerCase(),
        moreInfo: category.replaceAll(' ', '_').toLowerCase(),
      });
    });

    $('.splash-container').on('click', 'a', function (e) {
      let element = e.currentTarget;
      let currentRef = $(element).data('ref');
      pushDaralayer({
        eventID: '31',
        eventCategory: 'language_country',
        eventAction: 'splash_page',
        eventLabel: currentRef.toLocaleLowerCase(),
      });
    });

    $('.banner-overlay').on('click', 'a , button', function (e) {
      let element = e.currentTarget;
      let currentRef = $(element).data('ref');
      let currentHref= $(element).prop('href');
      pushDaralayer({
        eventID: '32',
        eventCategory: 'top_banner',
        eventAction: currentRef.toLocaleLowerCase(),
        eventLabel: currentHref || 'none',
      });
    });

    $('.btn-return').on('click', function (e) {
      let element = e.currentTarget;
      let orderID = $(element).data('order');
      let orderQty = $(element).data('quantity');
      pushDaralayer({
        eventID: '33',
        eventCategory: 'returned_product',
        eventAction: 'returned_product_step1',
        eventLabel: orderID,
        moreInfo: parseInt(orderQty),
      });
    });
  }.bind(this),
};
