'use strict';

let debounce = require('lodash/debounce');
let endpoint = $('.suggestions-wrapper').data('url');
let minChars = 0;
let UP_KEY = 38;
let DOWN_KEY = 40;
let DIRECTION_DOWN = 1;
let DIRECTION_UP = -1;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
  return $(scope).parents('.header-search').length > 0
    ? $(scope).parents('.header-search').find('.suggestions-wrapper')
    : $(scope).parents('.header-search-mobile').find('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
  return !!$(scope).closest('.header-search-mobile').length;
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response, scope) {
  let $suggestionsWrapper = getSuggestionsWrapper(scope).empty();

  $.spinner().stop();

  if (response.suggestions) {
    $suggestionsWrapper.append(response.suggestions).addClass('is-visible');
    $(scope).siblings('.js-reset-button').addClass('is-visible');

    // Trigger screen reader by setting aria-describedby with the new suggestion message.
    let suggestionsList = $('.suggestions-wrapper .item');
    if ($(suggestionsList).length) {
      $('input.search-field').attr('aria-describedby', 'search-result-count');
    } else {
      $('input.search-field').removeAttr('aria-describedby');
    }
  } else {
    $suggestionsWrapper.removeClass('is-visible');
  }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
  if ($(scope).val().length >= minChars) {
    $.spinner().start();
    $.ajax({
      context: scope,
      url: endpoint + encodeURIComponent($(scope).val()),
      method: 'GET',
      success: function (data) {
        processResponse(data, scope);
      },
      error: function () {
        $.spinner().stop();
      },
    });
  } else {
    $(scope).siblings('.js-reset-button').removeClass('is-visible');
    clearModals();
    getSuggestionsWrapper(scope).empty();
  }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
  // get all li elements in the suggestions list
  let suggestionsList = $('.suggestions-wrapper .item');
  if (suggestionsList.filter('.selected').length === 0) {
    suggestionsList.first().addClass('selected');
    $('.js-search-field').each(function () {
      $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
    });
  } else {
    suggestionsList.each(function (index) {
      let idx = index + direction;
      if ($(this).hasClass('selected')) {
        $(this).removeClass('selected');
        $(this).removeAttr('aria-selected');
        if (suggestionsList.eq(idx).length !== 0) {
          suggestionsList.eq(idx).addClass('selected');
          suggestionsList.eq(idx).attr('aria-selected', true);
          $(this).removeProp('aria-selected');
          $('.js-search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
          });
        } else {
          suggestionsList.first().addClass('selected');
          suggestionsList.first().attr('aria-selected', true);
          $('.js-search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
          });
        }
        return false;
      }
      return true;
    });
  }
}

module.exports = function () {
  $('form[name="simpleSearch"]').on('bouncerFormValid', function (e) {
    let suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length !== 0) {
      e.preventDefault();
      suggestionsList.filter('.selected').find('a')[0].click();
    }
  });

  $('input.search-field').each(function () {
    /**
     * Use debounce to avoid making an Ajax call on every single key press by waiting a few
     * hundred milliseconds before making the request. Without debounce, the user sees the
     * browser blink with every key press.
     */
    let debounceSuggestions = debounce(getSuggestions, 300);
    $(this).on('keyup focus', function (e) {
      // Capture Down/Up Arrow Key Events
      switch (e.which) {
        case DOWN_KEY:
          handleArrow(DIRECTION_DOWN);
          e.preventDefault(); // prevent moving the cursor
          break;
        case UP_KEY:
          handleArrow(DIRECTION_UP);
          e.preventDefault(); // prevent moving the cursor
          break;
        default:
          debounceSuggestions(this, e);
      }
    });
  });

  $('body').on('click', function (e) {
    if (!$('.header-search').has(e.target).length > 0 && !$(e.target).hasClass('icon-search')) {
      $('.suggestions-wrapper').removeClass('is-visible');
    }
  });

  $('.js-reset-button').on('click keydown', function (e) {
    if (e.type == 'click' || e.key == 'Enter') {
      $(this).removeClass('is-visible');
      $(this).siblings('.js-search-field').val('');
      $(this).siblings('.js-search-field').focus();
    }
  });

  $('.search-form').on('bouncerFormValid', function (e) {
    e.preventDefault();
    let $form = $(this);
    if ($form.find('.js-search-field').val() !== '') {
      $form.submit();
    }
  });
};
