'use strict';

module.exports = function () {
  let minicartCloseButton = document.querySelector('.minicart-modal .modal-close');
  let minicartGoToShoppingBag = document.querySelector('.link-shopping-bag');
  let minicartAction = document.querySelectorAll('.minicart-action');

  $(minicartCloseButton).on('click', function () {
    $(document).trigger('minicart:close');
  });

  $(minicartGoToShoppingBag).on('click', function () {
    $(document).trigger('minicart:shoppingBag');
  });

  $(minicartAction).on('click', function () {
    $(minicartAction).removeClass('is-open');
  });
};
